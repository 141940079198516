import Hero from "../layouts/hero";

import Assortment from "../layouts/assortment";
import NewsLetter from "../layouts/newsletter";
import AssortmentRest from "../layouts/assortment/assortyment__rest";
import ChangingListImage from "../layouts/changingListImage";
import Banner from "../layouts/banner-intro";
import ContactAndPlaces from "../layouts/contactAndPlaces";
import SectionNumbers from "../layouts/sectionNumbers";
import ImageAndText from "../layouts/ImageAndText";
import ImageAndCarousel from "../layouts/ImageAndCarousel";
import Ecology from "../layouts/ecology";
import ListPostIntro from "../layouts/listPostsIntro";
import EBookIntro from "../layouts/ebookIntro";

const currentSections = {
  Page_Grouppage_Sections_SectionHero: Hero,
  Page_Grouppage_Sections_SectionNewsletter: NewsLetter,

  Page_Grouppage_Sections_SectionAsortyment: Assortment,
  Page_Grouppage_Sections_SectionRestProducts: AssortmentRest,
  Page_Grouppage_Sections_SectionImageAndText: ImageAndText,
  Page_Grouppage_Sections_SectionNumbers: SectionNumbers,
  Page_Grouppage_Sections_SectionChangingListImage: ChangingListImage,
  Page_Grouppage_Sections_SectionImageAndCarousel: ImageAndCarousel,
  Page_Grouppage_Sections_SectionImageAndTextPrimary: Ecology,
  Page_Grouppage_Sections_SectionBannerIntro: Banner,
  Page_Grouppage_Sections_SectionListPostsIntro: ListPostIntro,
  Page_Grouppage_Sections_SectionContactAndPlaces: ContactAndPlaces,

  Page_Grouppage_Sections_SectionEbookIntro: EBookIntro,
};

export { currentSections };
